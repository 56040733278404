import React, { useState } from "react"
import {
  ChevronUpIcon,
  ChevronDownIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid";
import { ValidationObject } from "./usePubValidation"

type Props = {
  validationObject: ValidationObject
}
const ErrorDisplayer = ({ validationObject }: Props) => {

  const errorCount = Object.keys(validationObject.errors).length
  const [errorVisible, setErrorVisible] = useState(false); // State to control visibility of error section
  return (
    <>
      {Object.keys(validationObject.errors).length > 0 && (
        // <div className="bg-white border shadow-xl w-72 rounded-lg p-3 flex flex-col gap-3">
        <div
          className={`bg-white border shadow-xl w-72 rounded-lg p-3 flex flex-col gap-3 ${!errorVisible && validationObject.hasNewErrors ? "animate-bounce" : ""
            }`}>
          <div
            className={`flex flex-col gap-5 max-h-64 overflow-y-auto py-3 ${errorVisible ? "" : "hidden"
              }`}>
            {Object.entries(validationObject.errors).map(
              ([identifier, error]) => {
                const channelIdentifier = identifier.split("|")[0]
                return (
                  <div className="flex gap-1">
                    {/* <ExclamationCircleIcon className="size-4 mt-1 text-red-500" /> */}
                    <div className="flex flex-col gap-1">
                      <p className="font-medium text-red-500">
                        {error.message}
                      </p>
                      <p className="italic text-gray-500">
                        {error.inactionStrategyMessage}
                      </p>
                      {error.actions.map((a) => (
                        <button
                          className="text-white bg-green-600 font-medium px-1 py-px rounded w-fit hover:bg-green-700 duration-100"
                          type="button"
                          onClick={() => a.callback(
                            error.affectedTargets.length > 1 ? error.affectedTargets : [channelIdentifier]
                          )}
                        >
                          {a.label}
                        </button>
                      ))}
                    </div>
                  </div>
                );
              }
            )}
          </div>
          <div
            className="flex items-center justify-between cursor-pointer"
            onClick={() => setErrorVisible(!errorVisible)} // Close the error section
          >
            <div className="flex items-center gap-3">
              {/* Apply animation class conditionally if a new error is detected */}
              <div
                className={`bg-red-100 size-10 flex items-center justify-center rounded-md`}>
                <ExclamationTriangleIcon className="text-red-400 size-8" />
              </div>
              <p className="text-gray-500">
                {`${errorCount} ${errorCount == 1 ? "alerte" : "alertes"}`}
              </p>
            </div>
            {errorVisible ? (
              <ChevronDownIcon
                className="p-px text-gray-700 rounded-full cursor-pointer size-4 bg-gray-50 hover:bg-gray-200"
                onClick={() => setErrorVisible(false)} // Close the error section
              />
            ) : (
              <ChevronUpIcon
                className="p-px text-gray-700 rounded-full cursor-pointer size-4 bg-gray-50 hover:bg-gray-200"
                onClick={() => setErrorVisible(true)} // Open the error section
              />
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default ErrorDisplayer;
